body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Plotly modebar vertical orientation */
.js-plotly-plot .plotly .modebar {
  transform: translateY(70%);
}

.bg-mf {
  color: inherit !important;
  background-color: #f79646 !important;
}

.bg-mf-soft {
  color: inherit !important;
  background-color: #fdb880 !important;
}

.text-mf {
  color: #f79646 !important;
}

.text-mf-soft {
  color: #fdb880 !important;
}

.editor-wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-toolbar-class {
  border: 1px solid #ccc;
}

/* Badges colors by study type */
.bg-lito {
  color: inherit !important;
  background-color: #dce6f1 !important;
}

.bg-alt {
  color: inherit !important;
  background-color: #f2dcdb !important;
}

.bg-est {
  color: inherit !important;
  background-color: #ebf1de !important;
}

.bg-geof {
  color: inherit !important;
  background-color: #e4dfec !important;
}

.bg-geoq {
  color: inherit !important;
  background-color: #f2eab2 !important;
}

.bg-min {
  color: inherit !important;
  background-color: #ebd210 !important;
}

.bg-otros {
  color: inherit !important;
  background-color: #f0f0f0 !important;
}

.card.project:hover {
  box-shadow: var(--bs-box-shadow-lg) !important;
}
