.data-set-card {
  border-bottom: 1px solid #dcdcdc;
  padding: 25px 0;
}

.loading {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}
