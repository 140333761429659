.parameter-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.parameter-input {
  margin-top: 0 !important;
  background: 0 0 !important;
  padding: 2px 2px 1px !important;
  border-width: 0 0 1px !important;
  line-height: 26px;
  height: 30px;
  -ms-flex-preferred-size: 26px;
  border-radius: 0;
  border-style: solid;
  width: 100% !important;
  box-sizing: border-box;
}

.parameter-select {
  border-width: 0 0 1px !important;
  padding-top: 2px;
  padding-bottom: 2px;
}

.comparison-radio-text {
  text-align: center;
  width: 100%;
}

.comparison-radio-text span {
  font-weight: bold;
  text-align: center;
}
