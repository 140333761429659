.splash-screen .centered-horizontally {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.splash-screen .loading-dot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;
  animation: ease-in-out infinite alternate;
  animation-name: run;
  animation-duration: 1.2s;
}

@keyframes run {
  0% {
    left: -90px;
    color: #eee;
  }
  50% {
    color: #666;
  }
  100% {
    left: 90px;
    color: #eee;
  }
}
