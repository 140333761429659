.modal-90w {
  width: 90% !important;
  max-width: none !important;
}

span.tag {
  font-size: 0.7rem; /* Smaller text size */
  padding: 0.2em 0.4em; /* Adjust padding as needed */
  margin-left: 20px; /* Increase left margin to spread it from adjacent text */
  border-radius: 0.25rem; /* Rounded corners for aesthetic */
}
