.carousel-image {
  height: 300px;
  /* Set the desired fixed height for the images */
  object-fit: cover;
  /* Scale and crop the images to fill the fixed height container */
}

.lollipop-chart-grid {
  display: flex;
  justify-content: space-evenly; /* or any other alignment you prefer */
}

.chart-container {
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.info-card {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 20px;
}

.centered-spinner,
.col-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centered-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px; /* Adjust the value as needed */
}
.button-container {
  margin: 10px 10px; /* Adjust the horizontal spacing as needed */
}

.custom-tooltip {
  background-color: transparent;
  color: black; /* Set the color you prefer */
  border: none; /* Remove any border */
}

.list-title {
  text-align: center;
  margin-bottom: 30px; /* Add some space between the title and the list */
}

.list-container {
  background-color: #f8f9fa;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 auto;
}

.list-item {
  padding: 10px;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.active-item {
  background-color: orange !important;
  color: white !important;
  border: 1px solid orange !important;
}

.active-card {
  background-color: orange !important;
  color: white !important;
  border: 1px solid orange !important;
}
.trash-button {
  margin-left: auto;
}

.text-danger {
  font-weight: bold;
}

.centered-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.custom-carousel .carousel-indicators {
  bottom: 15px;
}

.carousel-control-next-icon {
  background-color: black;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.carousel-control-prev-icon {
  background-color: black;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.custom-list-group {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Custom CSS */
.custom-card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.custom-card .card {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.custom-accordion {
  border: 1px solid #ddd; /* Add the desired border style here */
  border-radius: 5px; /* Add border-radius if needed */
}

.accordion-custom {
  min-width: 100%; /* or any specific width you prefer */
  margin: auto; /* centers the accordion if its width is less than its parent's width */
}
.accordion-full-width {
  width: 100%; /* makes the accordion fill the width of its parent */
}

/* Custom CSS for rounded borders and padding */
.column-container {
  border: 2px solid #dee2e6; /* Bootstrap's default border color */
  border-radius: 0.25rem; /* Adjust the border-radius as needed */
  padding: 1rem; /* Adjust padding inside the container */
  margin-bottom: 1rem; /* Space between columns if stacked */
}
