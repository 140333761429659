.centered-horizontally {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.bottom-border {
  border-bottom: 1px solid #dcdcdc;
}

.result-container {
  border: 1px solid #dcdcdc;
  padding: 32px;
}

.bg-lito {
  color: inherit !important;
  background-color: #dce6f1 !important;
}

.bg-alt {
  color: inherit !important;
  background-color: #f2dcdb !important;
}

.bg-est {
  color: inherit !important;
  background-color: #ebf1de !important;
}

.bg-geof {
  color: inherit !important;
  background-color: #e4dfec !important;
}

.bg-geoq {
  color: inherit !important;
  background-color: #f2eab2 !important;
}

.bg-min {
  color: inherit !important;
  background-color: #ebd210 !important;
}

.bg-otros {
  color: inherit !important;
  background-color: #f0f0f0 !important;
}

.modal-90w {
  width: 90%;
}
